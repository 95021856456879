import React from "react";

const Loading = () => (
  <div className="card col" id="content-loading">
    <div className="card-body">
      <div className="qg-spinner qg-absolute-center" role="status">
        <div className="spinner-border"></div>
        <div className="qg-spinner-label">Loading...</div>
      </div>
    </div>
  </div>
);

export default Loading;
