import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom';
import * as Constants from "../constants"

const SESSION_IDLE_MINUTES = process.env.react_app_timeout;

const SessionManager = (props) => {

    const navigate = useNavigate();
    if (Constants.DEBUG) { console.log('Session manager started. Timeout set to', process.env.react_app_timeout, "minutes."); }

    const handleOnIdle = () => {
        if (Constants.DEBUG) { console.log('User idle, last active', getLastActiveTime()); }
        navigate("/SessionTimeout", { replace: true });
    }

    const { getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * Number(SESSION_IDLE_MINUTES),
        onIdle: handleOnIdle,
        debounce: 500,
    })

    return null;
}

export default SessionManager;