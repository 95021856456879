import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { getServiceStatus, UP } from "../utils/ServiceAvailabilityCheck";
import Loading from "../components/Loading";

/**
 * Displays the welcome page from where the user can choose to either login or signup
 * directly via Auth0
 * 
 * @returns the page
 */
export const Welcome = () => {

    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const serviceStatus = async () => {
            const serviceStatus = await getServiceStatus();
            if (serviceStatus !== UP) {
                navigate("/service-unavailable?message=" + serviceStatus, { replace: true });
            } else {
                setIsLoading(false);
            }
        };
        serviceStatus();
    }, [navigate]);

    if(isLoading) {
        // Checking service status before displaying the page
        return <Loading />;
    }

    return (
        <div>
            <h1>Queensland Digital Licence App</h1>

            <div style={{ margin: "0 auto" }}>

                <p>In order to obtain your Digital Licence App you must have a Queensland Digital Identity (QDI).</p>
                <p>To sign up for a QDI you will need to provide some forms of identification that will be verified during the sign up process.</p>
                <p>If you already have a QDI please use the log in option and enter your QDI email and password.</p>

                <ol className="questions">
                    <li className="footer">
                        <ul className="actions">

                            <li>
                                <Link id="signup" to="/signup">
                                    <strong>
                                        <button id="signUpButton" type="button" className="qg-btn btn-primary">Sign-up</button>
                                    </strong>
                                </Link>
                            </li>

                            <li>
                                <Link id="login" to="/login">
                                    <strong>
                                        <button id="loginButton" type="button" className="qg-btn btn-outline-dark">Login</button>
                                    </strong>
                                </Link>
                            </li>

                        </ul>
                    </li>
                </ol>

            </div>

        </div>
    );

}

export default Welcome;