import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";

const onRedirectCallback = async appState => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

// Please see https://auth0.github.io/auth0-react/interfaces/Auth0ProviderOptions.html
// for a full list of the available properties on the provider
//
const providerConfig = {
  domain: process.env.react_app_auth0_domain,
  clientId: process.env.react_app_auth0_clientId,
  ...(process.env.react_app_audience ? { audience: process.env.react_app_audience } : null),
  acr_values: process.env.react_app_acr_values,
  redirectUri: process.env.react_app_redirectUri,
  ip_uplift_url: process.env.react_app_ip_uplift_url,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  scope: 'identity_proofing_number offline_access dla_info',
  crossOriginAuthentication: true,
  onRedirectCallback
};
ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>,
  document.getElementById("app-root")
);
