export const CONTACT_US_URL =                       "https://www.qld.gov.au/contact-us";
export const DIGITAL_LICENCE_APP_WEBSITE =          "https://www.qld.gov.au/transport/projects/digital-licence";
export const DIGITAL_LIC_HELP =                     "https://www.qld.gov.au/transport/projects/digital-licence/app-users";
export const QUEENSLAND_DIGITAL_IDENTITY_WEBPAGE =  "https://www.qld.gov.au/digital-identity";

export const TROUBLESHOOTING_HELP_URL = "https://www.qld.gov.au/transport/projects/digital-licence/queensland-digital-identity";

export const CONTACT_US_TEL = "tel:1800317389";
export const CONTACT_US_TEL_DISPLAY = "1800 317 389";
export const DEBUG = process.env.react_app_debug === 'true' || false;
export const APIGATEWAY_URI = process.env.react_app_apigateway_uri;

export {};