import { Link } from "react-router-dom";
import { DEBUG } from "../constants";

const Unauthorized = () => {

    if (DEBUG) { console.log("Unauthorized access"); }

    return (
        <>
            <div>
                <h1>Queensland Digital Licence App</h1>

                <div className="alert alert-danger" role="alert">
                    <h2><i className="fa fa-times-circle" style={{ float: "left" }}></i>Unauthorised Access</h2>

                    <p>The page you attempted to access cannot be viewed without valid permissions.</p>
                    <p>Your access may have been invalidated for some reason.</p>

                </div>

                <p>In order to install and use the Queensland Digital LicenceApp you must have a Queensland Digital Identity (QDI).</p>
                <p>If you are signing up for a QDI, you will need to provide some forms of identification, which will be verified during the process.</p>

            </div>

            <ol className="questions">
                    <li className="footer">
                        <ul className="actions">

                             <li>
                                <Link id="signup" to="/signup">
                                    <strong>
                                        <button id="signUpButton" type="button" className="qg-btn btn-primary">Sign-up</button>
                                    </strong>
                                </Link>
                            </li>

                            <li>
                                <Link id="login" to="/login">
                                    <strong>
                                        <button id="loginButton" type="button" className="qg-btn btn-outline-dark">Login</button>
                                    </strong>
                                </Link>
                            </li>

                        </ul>
                    </li>
                </ol>
        </>
    )
};

export default Unauthorized;