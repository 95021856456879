import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import * as Constants from "../constants"
/**
 * Displays a session timeout error page
 * @param {*} props 
 * @returns the page
 */
export default function SessionTimeout(props) {

    const { isAuthenticated, logout } = useAuth0();
    
    useEffect(() => {
        if(isAuthenticated) {
            if (Constants.DEBUG) { console.log("Session timed out. Logging user out from Auth0..."); }
            logout({ localOnly: true, });
            localStorage.clear();
            sessionStorage.clear();  
        }
    }, [isAuthenticated, logout]);

    return (
        <div style={{ margin: "0 auto" }}>

            <h1>Your session has expired due to inactivity</h1>

            <div className="alert alert-danger" role="alert">
                <h2><i className="fa fa-times-circle" style={{ float: "left" }}></i></h2>
                Select 'Cancel' or 'X' to close this browser you will be returned to the opening screen of the Digital Licence app.
            </div>

        </div>
    );
}