import * as Constants from "../constants"
import awsconfig from "../aws-exports";
import { API } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

const UP = "UP";

/**
 * Makes a call to backend API which will return the state of the core services as set
 * in the support portal by support personel, UP/DOWN{reason}
 * 
 * If services are 'UP', or the call to fetch service status fails, return 'UP'
 * otherwise, return the reason why services are down.
 *  
 * @returns string denoting state of services
 */
async function getServiceStatus(){

    if (Constants.DEBUG) console.log('Checking service availability...');
    API.configure(awsconfig);
    
    try {
        let response = await API.get("DlaApi", `/service-status?service=DLA`, {
            headers: { "X-Request-Id": uuidv4(), }
        });
    
        if(response?.[0].service_name === 'DLA' && response?.[0].service_status === 'UP') {
            if (Constants.DEBUG) console.log('Service UP!');
            return UP;

        } else if(response?.[0].service_name === 'DLA' && response?.[0].service_status === 'DOWN') {
            if (Constants.DEBUG) console.log('Service down, %s', response?.[0].status_message);
            return response?.[0].status_message;

        } else {
            if (Constants.DEBUG) console.log('Unhandled exception...');
            throw new Error("Unhandled response.")
        }

    } catch (err) {
        if (err.errors) {
            if (Constants.DEBUG)
                console.log('Error received querying service status. Do nothing, continue on...', err.errors[0].message);
        }
        // If the call to service status check failed, it does not necassarily mean services are down, so assume they are UP
        // If dependent services are indeed down, the user will encounter a system error later on at some point.
        return UP;
    } 
}

export {getServiceStatus, UP};
