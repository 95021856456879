import React, { Fragment } from "react";

import Welcome from "../content/Welcome";

const Home = () => (
  <Fragment>
    <Welcome />
  </Fragment>
);

export default Home;
