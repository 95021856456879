import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSearchParams } from "react-router-dom";
import * as Constants from "../constants";

export default function ServiceUnavailable() {

    const { isAuthenticated, logout } = useAuth0();
    const [searchParams] = useSearchParams();
    let message = searchParams.get("message");
    message = (message && message !== 'undefined') ? message : "The Queensland Government apologise for any inconvenience.";


    useEffect(() => {
        if (isAuthenticated) {
            if (Constants.DEBUG) { console.log("Service unavailable, %s. Logging user out from Auth0...", message); }
            logout({ localOnly: true, });
            localStorage.clear();
            sessionStorage.clear();
        }
    }, [isAuthenticated, logout]);

    return (
        <>
            <div style={{ margin: "0 auto" }}>

                <h1>Queensland Digital Licence App</h1>

                <div className="alert alert-danger" role="alert">
                    <h2><i className="fa fa-times-circle" style={{ float: "left" }}></i>Service unavailable</h2>

                    <p>Queensland Digital Licence App is currently unavailable. {message}</p>
                    
                    <p>If you require immediate assistance please <a href={Constants.CONTACT_US_URL}>contact us</a>.</p>
                </div>

            </div>
        </>
    )
}