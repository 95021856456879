import React from "react";
import history from "../utils/history";
/**
 * Displays Page Not Found
 * @returns the page
 */
 const PageNotFound = () => {

    console.log("Page Not Found");

    function doBack() {
        history.go(-1);
      }
    
    return (
        <div style={{ margin: "0 auto" }}>

            <h1>Page Not Found</h1>

            <div className="alert alert-danger" role="alert">

                <h2><i className="fa fa-times-circle" style={{ float: "left" }}></i></h2>
                The page you were looking for can't be found or doesn't exist. Select Back to return to your previous page.

            </div>

            <button id="back" type="button"
                className="qg-btn btn-primary"
                onClick={() => doBack()}>
                Back
            </button>

        </div>
    );
};

export default PageNotFound;