import React from 'react';
import * as Constants from "../constants";

const Aside = () => (
  <aside id="qg-secondary-content">
    <div id="asides" className="qg-aside qg-tip" role="complementary">

      <h2><span className="fa fa-lightbulb-o fa-2x" aria-hidden="true"></span> Need help?</h2>

      <p>Visit the <a href={Constants.DIGITAL_LICENCE_APP_WEBSITE} target="_blank" rel="noopener">Digital Licence app website</a>
      to learn more about the app and troubleshoot any problems.</p>

    </div>
  </aside>
);

export default Aside;