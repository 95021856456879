
import * as Constants from "./constants"

const awsmobile =  {
    "aws_appsync_authenticationType": "AWS_LAMBDA",
    API: {
        endpoints: [
          {
            name: "DlaApi",
            endpoint: Constants.APIGATEWAY_URI,
          }
        ]
      }
};

export default awsmobile;