import React from "react";
import * as Constants from '../constants';
import Breadcrumbs from "./Breadcrumbs";
import SessionManager from "./SessionManager";

const Header = () => {

  const app_help = Constants.DIGITAL_LIC_HELP;

  return (
    <>
      <SessionManager />

      <header id="qg-site-header" className="qg-site-header sticky">
        <div className="container-fluid qg-site-width qg-site-header-top">
          <div className="row align-items-center justify-content-start">
            <button id="qg-show-menu" className="qg-show-menu" aria-label="Search" data-toggle="collapse" data-target=".qg-navigation" aria-expanded="false" aria-controls="qg-site-nav qg-portal-links">
              <div className="qg-global-icons ">
                <div className="qg-icon--menu-default">
                  <svg width="512px" height="512px" viewBox="0 0 512 512">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect x="72" y="95" width="368" height="60" rx="15"></rect>
                      <rect x="72" y="226" width="368" height="59" rx="15"></rect>
                      <rect x="72" y="356" width="368" height="60" rx="15"></rect>
                    </g>
                  </svg>
                </div>
                <div className="qg-icon--menu-close">
                  <svg width="512px" height="512px" viewBox="0 0 512 512">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                      <rect transform="translate(256.500000, 256.000000) scale(1, -1) rotate(45.000000) translate(-256.500000, -256.000000) " x="32" y="220" width="449" height="72" rx="15"></rect>
                      <rect transform="translate(256.500000, 256.000000) rotate(45.000000) translate(-256.500000, -256.000000) " x="32" y="220" width="449" height="72" rx="15"></rect>
                    </g>
                  </svg>
                </div>
              </div>
              <span>Menu</span>
            </button>
            <div className="qg-coat-of-arms">
              <a href="//www.qld.gov.au" tabIndex="0">
                <img src="https://static.qgov.net.au/assets/v4/latest/images/coat-of-arms/qg-coa-white.svg" alt="Queensland Government" className="hidden-xs" />
                <img src="https://static.qgov.net.au/assets/v4/latest/images/coat-of-arms/qg-coa-stacked-white.svg" alt="Queensland Government" className="hidden-sm hidden-md hidden-lg" />
                <img src="https://static.qgov.net.au/assets/v4/latest/images/coat-of-arms/qg-coa-stacked.svg" alt="Queensland Government" className="hidden-xs hidden-sm hidden-md hidden-lg qg-print-coat-of-arms" />
              </a>
            </div>
            <div className="alignment-placeholder"></div>
          </div>
        </div>
        <div id="qg-portal-links" className="qg-portal-links qg-navigation collapse">
          <div className="container-fluid qg-site-width">
            <nav className="navbar navbar-expand">
              <div className="collapse navbar-collapse" id="qgPortalLinksDropdown">
              </div >
            </nav >
          </div >
        </div >

        <div className="container-fluid qg-site-width qg-site-header-bottom">
          <div className="row align-items-center justify-content-between">

            <nav id="qg-site-nav" className="qg-navigation collapse" role="navigation" aria-label="Main navigation" style={{ width: '100%' }}>
              <ul className="nav" style={{ display: 'block' }}>
                <li className="nav-item">
                  <a className="nav-link" href={app_help} data-analytics-link-group="qg-primary-nav-link">Digital Licence app help</a>
                </li>
              </ul>
            </nav>

          </div>
        </div >
      </header >

      {/* <Breadcrumbs /> */}
      
    </>
  );
};

export default Header;
