import React from "react";
import Loading from "../components/Loading";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * CIDM - Calls Auth0 directly to initiate a login or a signup
 * Auth0 will then redirect to uplift portal if required
 * 
 * @returns The page
 */
const SignUp = () => {
    const { loginWithRedirect } = useAuth0();
    loginWithRedirect({ screen_hint: "signup", prompt: "login", acr_values: "urn:id.gov.au:tdif:acr:ip2:cl2" })
    return (<><DisplayGlif /></>)
}

const Login = () => {
    const { loginWithRedirect } = useAuth0();
    loginWithRedirect({ prompt: "login", acr_values: "urn:id.gov.au:tdif:acr:ip2:cl2" });
    return (<><DisplayGlif /></>);
  
}

const DisplayGlif = () => {
    return (
        <>
            <h1>Queensland Digital Licence App</h1>
            <Loading />

        </>
    );
}

export { SignUp, Login }